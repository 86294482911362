import React, { FC } from "react";
import styled from "styled-components";
import { BasicTemplate } from "@templates/BasicTemplate";
import { useTranslation } from "react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Contact } from "@components/molecules/Contact";
import { graphql } from "gatsby";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import { WebsiteTitle } from "@components/atoms/WebsiteTitle";

const StyledImagesWrapper = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledImageWrapper = styled.div`
  margin: 0 auto 20px;
`;

const Credentials: FC<{
  data: { strapiReview: { reviews: { localFile: ChildImageSharp }[]} };
}> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <BasicTemplate
      title={t("title-credentials")}
      description={t("description-credentials")}
    >
      <WebsiteTitle>{t("credentials:title")}</WebsiteTitle>
      <StyledImagesWrapper>
        {data.strapiReview.reviews.map((img, index) => (
          <StyledImageWrapper key={index}>
            <GatsbyImage
              image={getImage(img.localFile.childImageSharp?.gatsbyImageData)}
              alt=""
              key={index}
            />
          </StyledImageWrapper>
        ))}
      </StyledImagesWrapper>
      <Contact />
    </BasicTemplate>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    strapiReview {
      reviews {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80)
          }
        }
      }
    }
  }
`;

export default Credentials;
